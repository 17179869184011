import { render, staticRenderFns } from "./PtInfo.vue?vue&type=template&id=2ee876ec&scoped=true&"
import script from "./PtInfo.vue?vue&type=script&lang=ts&"
export * from "./PtInfo.vue?vue&type=script&lang=ts&"
import style0 from "@/assets/style/course-info.css?vue&type=style&index=0&id=2ee876ec&scoped=true&lang=css&"
import style1 from "./PtInfo.vue?vue&type=style&index=1&id=2ee876ec&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ee876ec",
  null
  
)

export default component.exports